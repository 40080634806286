<template>
	<v-container fill-height fluid grid-list-xl>
		<v-layout wrap>
			<v-flex md12 sm12 lg12>
				<center>
					<v-img
						class="white--text align-end"
						height="2562px"
						max-height="256"
						max-width="400"
						width="400px"
						src="img/logo.png"
					></v-img>
				</center>
			</v-flex>

			<v-flex md12 sm12 lg4>
				<base-card class="justify-center" color="#138dd3">
					<a href="https://datapop1.bandcamp.com/album/sterne-album">
						<v-img
							class="white--text align-end"
							height="2562px"
							max-height="256"
							max-width="256"
							width="256px"
							src="img/sterne_cd.jpg"
						></v-img>
					</a>
					<h4 class="title font-weight-light">STERNE</h4>
					<p class="category d-inline-flex font-weight-light">CD AND DIGITAL</p>
				</base-card>
			</v-flex>
			<v-flex md12 sm12 lg4 class="justify-center">
				<base-card color="#138dd3">
					<NuxtLink to="/catalog">
						<v-img
							class="white--text align-end"
							height="2562px"
							max-height="256"
							max-width="256"
							width="256px"
							src="img/ett.jpg"
							contain
						></v-img>
					</NuxtLink>
					<h4 class="title font-weight-light">CATALOG</h4>
					<p class="category d-inline-flex font-weight-light">DIGITAL RELEASES</p>
				</base-card>
			</v-flex>
			<v-flex md12 sm12 lg4 class="justify-center">
				<base-card color="#138dd3">
					<a href="https://datapop1.bandcamp.com/album/pop">
						<v-img
							class="white--text align-end"
							height="2562px"
							max-height="256"
							max-width="256"
							width="256px"
							src="img/pop_cd.jpg"
						></v-img>
					</a>
					<h4 class="title font-weight-light">POP</h4>
					<p class="category d-inline-flex font-weight-light">CD AND DIGITAL</p>
				</base-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import materialCard from '~/components/material/Card';
import BaseCard from '~/components/base/Card';

export default {
	layout: 'dashboard',
	components: {
		BaseCard,
		materialCard,
	},
	data() {
		return {};
	},
	computed: {
		ctransparent() {
			return rgba(254, 254, 254, 1);
		},
	},
	mounted() {
		this.$nextTick(() => {});
	},
};
</script>
 